<script setup lang="ts">
import { useClient, dayjs } from '~/utils'
import env from '/env.js'

const { data } = useClient('https://geniuslink.com/blog/wp-json/wp/v2/posts', {
  query: {

    _fields: 'title,link,date',
    per_page: 6,
    page: 1
  }
})

const { WEBSITE_URL } = env

</script>

<template>
  <!-- Footer -->
  <div class="footer">
    <div class="container">
      <div class="footer-container">
        <section class="links">

          <h2>Learn More</h2>

          <table class="sitemap">
            <tr>
              <td>
                <h4>
                  <a
                    :href="`${WEBSITE_URL}/how-it-works`"
                    target="_blank"
                  >How it Works</a>
                </h4>
                <ul>
                  <li><a :href="`${WEBSITE_URL}/how-it-works/for-affiliates`">For Affiliates</a></li>
                  <li><a :href="`${WEBSITE_URL}/how-it-works/for-creators`">For Creators</a></li>
                  <li><a :href="`${WEBSITE_URL}/how-it-works/for-marketers`">For Brands</a></li>
                  <li><a :href="`${WEBSITE_URL}/how-it-works/for-retailers`">For Retailers</a></li>
                </ul>
              </td>
              <td>
                <h4>
                  <a
                    :href="`${WEBSITE_URL}/features`"
                    target="_blank"
                  >Product Info</a>
                </h4>
                <ul>
                  <li><a :href="`${WEBSITE_URL}/features`">Features</a></li>
                  <li><a :href="`${WEBSITE_URL}/pricing`">Pricing</a></li>
                  <li><a :href="`${WEBSITE_URL}/privacy`">Privacy</a></li>
                  <li><a :href="`${WEBSITE_URL}/terms`">Terms</a></li>
                  <li><a :href="`${WEBSITE_URL}/affiliate-disclosure`">Affiliate Disclosure</a></li>
                  <li><a :href="`${WEBSITE_URL}/spam-policy`">Spam Policy</a></li>
                </ul>

              </td>
              <td>
                <h4>
                  <a
                    :href="`${WEBSITE_URL}/about`"
                    target="_blank"
                  >About</a>
                </h4>
                <ul>
                  <li>
                    <a
                      :href="`${WEBSITE_URL}/about`"
                    >The Team</a>
                  </li>
                  <li>
                    <a
                      :href="`${WEBSITE_URL}/jobs`"
                    >Jobs</a>
                  </li>
                  <li>
                    <a
                      :href="`${WEBSITE_URL}/contact`"
                    >Contact</a>
                  </li>
                  <li>
                    <a
                      :href="`${WEBSITE_URL}/happy-clients`"
                    >Testimonials</a>
                  </li>
                </ul>
              </td>
              <td>
                <h4>
                  <a
                    href="https://kb.geni.us"
                    target="_blank"
                  >Support</a>
                </h4>
                <ul>
                  <li>
                    <a
                      href="https://intercom.geni.us/start-here"
                      target="_blank"
                    >Getting Started</a>
                  </li>
                  <li>
                    <a
                      href="https://kb.geni.us"
                      target="_blank"
                    >Documentation</a>
                  </li>
                  <li>
                    <a
                      href="https://intercom.geni.us/faqs"
                      target="_blank"
                    >FAQ</a>
                  </li>
                </ul>

              </td>
            </tr>
          </table>
        </section>
        <section class="posts">
          <h2>
            <a
              :href="`${WEBSITE_URL}/blog/`"
              target="_blank"
            >Blog</a>
          </h2>
          <ul
            class="news"
            id="newsteasers"
            v-if="data"
          >
            <li v-for="post in data" :key="post.link">
              <span class="date">{{ dayjs(post.date).format('MMM D, YYYY') }}</span>
              <a
                :href="post.link"
                target="_blank"
              >{{ post.title.rendered }}</a>
            </li>
          </ul>
          <div class="sharing_area">
            <a
              href="https://www.facebook.com/geniuslink"
              class="sharing_icon"
              target="_blank"
            >
              <img
                src="/images/sharing_facebook.png"
                width="40"
                height="40"
                alt="Geniuslink on Facebook"
              >
            </a>
            <a
              href="https://twitter.com/geniuslink"
              class="sharing_icon"
              target="_blank"
            >
              <img
                src="/images/sharing_twitter.png"
                width="40"
                height="40"
                alt="Geniuslink on Twitter"
              >
            </a>
            <a
              href="https://www.instagram.com/geniuslink/"
              class="sharing_icon"
              target="_blank"
            >
              <img
                src="/images/sharing_instagram.png"
                width="40"
                height="40"
                alt="Geniuslink on Instagram"
              >
            </a>
            <a
              href="https://www.linkedin.com/company/geniuslink"
              class="sharing_icon"
              target="_blank"
            >
              <img
                src="/images/sharing_linkedin.png"
                width="40"
                height="40"
                alt="Geniuslink on Linkedin"
              >
            </a>
          </div>
        </section>
      </div>
    </div>
    <div class="footerlogo">
      <img
        src="/images/logo_footer.png"
        width="82"
        height="17"
        alt="GeniusLink"
      >
      <div class="pagebottom">
        &copy; Copyright {{ new Date().getFullYear() }} by GeoRiot Networks, Inc. dba Geniuslink
      </div>
    </div>

  </div>

  <!-- Bootstrap Container for Footer -->

  <!-- End Footer -->

</template>

<style scoped>
.footer {
  background-color: var(--surface-200);
  margin-top: 4rem;
  padding: 2rem 0;
  color: var(--surface-600);

  a, a:link, a:visited {
    color: var(--surface-800);
  }

  h2 {
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
  }

  h4 {
    font-size: 0.8571rem;
    text-transform: uppercase;
    border-bottom: 1px solid var(--surface-500);
    line-height: 1.5;
    margin-bottom: 0.714rem;
  }
}
table.sitemap {
  border-collapse: collapse;
  width: 100%;

  td {
    vertical-align: top;
    padding-right: 15px;
  }
  ul {
    list-style: none;
    margin: 0 0 10px 0;
    font-size: 0.8571rem;
    padding: 0;
  }
  li {
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 1;
  }
}
.sharing_area {
  display: flex;
  gap: 1rem;
}
.footerlogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 0;
  gap: 0.5rem;
}

.container {
  display: flex;
  justify-content: center;
}

.footer-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 2rem;
}
@media (min-width: 992px) {
  .footer-container {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  .posts {
    order: 1;
  }
  .links {
    order: 2;
  }
}
@media print {
  .footer-container {
    display: none;
  }
}
.news {
  list-style: none;
  margin: 0 0 10px 0;
  font-size: 0.8571rem;
  padding: 0;
  padding-bottom: 2rem;
  li {
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 18px;
  }
  a {
    display: block;
    overflow: hidden;
  }
  .date {
    color: var(--surface-600);
    white-space: nowrap;
    margin: 0;
    width: 80px;
    float: left;
  }
}
</style>
